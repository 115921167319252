<template>
    <div class="client">
        <div class="menu">
            <span class="item" :class="{ active: Active == 'Information'}" @click="Active = 'Information'">Algemeen</span>
            <span class="item" :class="{ active: Active == 'Products'}" @click="Active = 'Products'">Artikelen</span>
            <span class="item" :class="{ active: Active == 'Workshop'}" @click="Active = 'Workshop'">Werkplaats</span>
        </div>

        <div class="block" v-if="Active == 'Information'">
            <div class="group">
                <span class="subtitle">Soort</span>
                <div class="sub-group">
                    <span>Land</span>
                    <select v-model="Client.customer_type_name">
                        <option value="Klant">Persoon</option>
                        <option value="Zakelijk">Zakelijk</option>
                    </select>
                </div>
            </div>

            <div class="group">
                <span class="subtitle">Naam</span>
                <div class="sub-group">
                    <span>Aanhef</span>
                    <select v-model="Client.title">
                        <option value="Dhr./Mevr.">Dhr. / Mevr.</option>
                        <option value="Dhr.">Dhr.</option>
                        <option value="Mevr.">Mevr.</option>
                        <option value="Fam.">Fam.</option>
                        <option value="Firma">Firma</option>
                        <option value=""></option>
                    </select>
                </div>
                <div class="sub-group">
                    <span>Voorletters</span>
                    <input type="text" v-model="Client.initials">
                </div>
                <div class="sub-group">
                    <span>Voornaam</span>
                    <input type="text" v-model="Client.first_name">
                </div>
                <div class="sub-group">
                    <span>Tussenvoegsel</span>
                    <input type="text" v-model="Client.insertion">
                </div>
                <div class="sub-group">
                    <span>Achternaam</span>
                    <input type="text" v-model="Client.name">
                </div>
            </div>

            <div class="group">
                <span class="subtitle">Adres</span>
                <div class="sub-group">
                    <span>Straat</span>
                    <input type="text" v-model="Client.street">
                </div>
                <div class="sub-group">
                    <span>Huisnummer</span>
                    <input type="text" v-model="Client.house_number" class="half">
                    <input type="text" v-model="Client.house_number_postfix" class="half">
                </div>
                <div class="sub-group">
                    <span>Postcode</span>
                    <input type="text" v-model="Client.postcode">
                </div>
                <div class="sub-group">
                    <span>Stad</span>
                    <input type="text" v-model="Client.city">
                </div>
                <div class="sub-group">
                    <span>Land</span>
                    <select v-model="Client.country_code_iso_3166">
                        <option value="NL">Nederland</option>
                        <option value="BE">Belgie</option>
                    </select>
                </div>
            </div>

            <div class="group" v-if="Client.phone_numbers">
                <span class="subtitle">Telefoonnummer</span>
                <div class="sub-group">
                    <span>Vast</span>
                    <input type="text" v-model="Client.phone_numbers[0].phone_number">
                </div>
                <div class="sub-group">
                    <span>Mobiel</span>
                    <input type="text" v-model="Client.phone_numbers[1].phone_number">
                </div>
            </div>

            <div class="group">
                <div class="sub-group">
                    <span>E-mailadres</span>
                    <input type="text" v-model="Client.email">
                </div>
            </div>
        </div>

        <div class="block" v-if="Active == 'Products'">
            <div class="article" v-for="(Article, i) in Articles" :key="i">
                <div class="image" v-if="Article.images">
                    <img v-if="Article.images[0]" :src="Article.images[0].url_thumb" alt="Thumbnail">
                </div>
                <div class="brand">
                    <span class="top">{{ Article.brand }} {{ Article.model }}</span>
                    <span class="bottom">{{ Article.color }}</span>
                </div>
                <div class="specs">
                    <span class="top">#{{ Article.object_id }}</span>
                    <span class="bottom">{{ Article.frame_id }}</span>
                </div>
            </div>
            <div class="article" v-if="Articles.length == 0">
                <span>Geen artikelen</span>
            </div>
        </div>

        <div class="block" v-if="Active == 'Workshop'">
            <div class="service" v-for="(ServiceItem, i) in Service" :key="i">
                <span class="title">{{ ServiceItem.title }}</span>
                <span class="date">{{ SetServiceDate(ServiceItem.scheduled_at) }}</span>
            </div>
            <div class="service" v-if="Service.length == 0">
                <span>Geen werkplaatsafspraken</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientItem',

    data () {
        return {
            Active: 'Information',
            Client: [],
            Articles: [],
            Service: []
        }
    },

    mounted () {
        this.GetClient()
    },

    methods: {
        GetClient () {
            this.$http.get(`https://api.wheels.nl/clients/${this.$route.params.id}`).then((response) => {
                if(response.data.client.customer_id) {
                    if(response.data.client) { this.Client = response.data.client } else { this.Client = [] }
                    if(response.data.articles) { this.Articles = response.data.articles } else { this.Articles = [] }
                    if(response.data.service) { this.Service = response.data.service } else { this.Service = [] }
                } else {
                    this.$router.push('/klanten')
                }
            })
        },

        SetServiceDate (e) {
            return this.$moment(e, 'YYYY-MM-DD').format('DD-MM-YYYY')
        }
    }
}
</script>

<style scoped>
.client {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: 100%;
    padding: 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: auto
}





.client .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.client .menu .item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
    height: 50px;
    border-bottom: solid 2px #d7d7d7;
    cursor: pointer;
}

.client .menu .item.active {
    color: #0171d7;
    font-weight: 700;
    border-bottom: solid 2px #0171d7;
}





.client .block {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding: 25px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 10px;
}

.client .block .subtitle {
    font-size: 20px;
    font-weight: 500;
}

.client .block .group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.client .block .group .sub-group {
    position: relative;
    display: flex;
    align-items: center;
    gap: 25px;
}

.client .block .group .sub-group span {
    font-size: 14px;
    width: 25%;
}

.client .block .group .sub-group input, .client .block .group .sub-group select {
    width: 75%;
    height: 40px;
    padding: 0 35px 0 10px;
    background: #fff;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    outline: 0;
}

.client .block .group .sub-group input.half, .client .block .group .sub-group select.half {
    width: calc((75% / 2) - 14px);
}





.client .block .article {
    display: flex;
    align-items: center;
    gap: 25px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.client .block .article .image {
    width: 100px;
    display: flex;
    align-items: center;
}

.client .block .article .image img {
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    background: #fff;
}

.client .block .article .brand {
    width: calc(50% - 50px);
    display: flex;
    flex-direction: column;
}

.client .block .article .brand .top {
    font-weight: 600;
}

.client .block .article .specs {
    width: calc(50% - 50px);
    display: flex;
    flex-direction: column;
}

.client .block .article .specs .top {
    font-weight: 600;
}





.client .block .service {
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.client .block .service .title {
    font-weight: 600;
}
</style>
<template>
    <div class="dashboard">
        <div class="block">
            <span class="title">Sales (aantallen)</span>
            <Bar :height="300" :width="500" class="chart" :data="Chart" :options="ChartOptions" />
        </div>
        <div class="block">
            <span class="title">Sales (omzet)</span>
            <Bar :height="300" :width="500" class="chart" :data="Chart" :options="ChartOptions" />
        </div>
        <div class="block">
            <span class="title">Service (aantallen)</span>
            <Bar :height="300" :width="500" class="chart" :data="Chart" :options="ChartOptions" />
        </div>
        <div class="block">
            <span class="title">Service (omzet)</span>
            <Bar :height="300" :width="500" class="chart" :data="Chart" :options="ChartOptions" />
        </div>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js'
ChartJS.register(Title, Tooltip, Legend, BarElement, PointElement, LinearScale, CategoryScale)

export default {
    name: 'DashboardView',

    components: {
        Bar
    },

    data () {
        return {
            Chart: {
                labels: ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'],
                datasets: [
                    {
                        label: '2023',
                        backgroundColor: '#96caff',
                        borderColor: '#96caff',
                        data: [Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1)]
                    },
                    {
                        label: '2024',
                        backgroundColor: '#0071d7',
                        borderColor: '#0071d7',
                        data: [Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1), Math.floor(Math.random() * (99 - 1 + 1) + 1)]
                    }
                ]
            },
            ChartOptions: {
                responsive: false,
                maintainAspectRatio: true,
                tension: 0.5,
                plugins: {
                    legend: {
                        display: false
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.dashboard {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 25px;
    padding: 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
}

.dashboard .block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.dashboard .block .title {
    font-size: 20px;
    font-weight: 600;
}
</style>
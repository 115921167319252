<template>
    <div class="bar">
        <span class="title">{{ $route.meta.Title }}</span>
    </div>
</template>

<script>
export default {
    name: 'TopBar'
}
</script>

<style scoped>
.bar {
    display: flex;
    align-items: center;
    padding: 0 50px;
    background: #fff;
}

.bar .title {
    font-size: 24px;
    font-weight: 500;
}
</style>
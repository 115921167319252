<template>
    <div class="stores">
        <div class="catalog">
            <span class="title">Winkels</span>
            <div class="list">
                <div class="list-item" v-for="Item in FilteredStores" :key="Item.Identifier">
                    <div class="status">
                        <span class="pill" :class="Item.Status">{{ SetStatus(Item.Status) }}</span>
                    </div>
                    <router-link :to="'/winkels/' + Item.Identifier" class="name">{{ Item.Name }}</router-link>
                    <div class="city">{{ Item.City }}</div>
                </div>
            </div>
        </div>
        <div class="filters">
            <span class="title">Filter</span>

            <div class="group" :class="{ active: Search }">
                <input v-model="Search" @change="GetStores(true)" class="search" type="text" placeholder="Zoek een winkel">
            </div>

            <span class="create" @click="this.$root.OpenCreateStore">Winkel aanmaken</span>

            <span class="reset" v-if="Filters.City || Filters.Status" @click="Reset">Reset</span>

            <div class="group" v-if="Stores">
                <span class="group-title">Stad</span>
                <div class="items">
                    <div class="item" v-for="(Item, i) in new Set(Stores.map(item => item.City))" :key="i">
                        <div class="row">
                            <div class="checkbox" @click="SetFilter('City', Item)">
                                <div class="checked" v-if="Filters.City == Item"></div>
                            </div>
                            <span class="name">{{ Item }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="group" v-if="Stores">
                <span class="group-title">Status</span>
                <div class="items">
                    <div class="item" v-for="(Item, i) in new Set(Stores.map(item => item.Status))" :key="i">
                        <div class="row">
                            <div class="checkbox" @click="SetFilter('Status', Item)">
                                <div class="checked" v-if="Filters.Status == Item"></div>
                            </div>
                            <span class="name">{{ SetStatus(Item) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoreOverview',

    data () {
        return {
            Search: '',
            Stores: [
                {
                    Identifier: 'a93aa584-9af1-4b74-89d5-839ea9db76d3',
                    Name: 'FRS Fietsen',
                    City: 'Zwijndrecht',
                    Status: 'O'
                },
                {
                    Identifier: 'c6b06e93-d641-4a94-a9be-5056a3f805c4',
                    Name: 'Fietsen ABC',
                    City: 'Gouda',
                    Status: 'C'
                },
                {
                    Identifier: 'acd9f7be-1630-4a75-91d3-44a6936bf1a8',
                    Name: 'Shop ZZZ',
                    City: 'Utrecht',
                    Status: 'A'
                }
            ],
            Filters: [],
            Selected: []
        }
    },

    methods: {
        GetStores (e) {
            console.log(e)
        },

        SetFilter (e, a) {
            if(this.Filters[e] == a) {
                this.Filters[e] = ''
            } else {
                this.Filters[e] = a
            }
        },

        SetStatus (e) {
            if(e == 'O') {
                return 'Actief'
            } else if(e == 'C') {
                return 'Concept'
            } else if(e == 'A') {
                return 'Archief'
            } else {
                return e
            }
        },

        Reset () {
            this.Filters = []
            this.Search = ''
        }
    },

    computed: {
        FilteredStores () {
            var Stores = this.Stores

            if(this.Filters.City) {
                Stores = Stores.filter(item => item.City == this.Filters.City)
            }

            if(this.Filters.Status) {
                Stores = Stores.filter(item => item.Status == this.Filters.Status)
            }

            if(this.Search) {
                Stores = Stores.filter(item => item.Name.toString().toLowerCase().includes(this.Search.toString().toLowerCase()))
            }

            return Stores
        }
    }
}
</script>

<style scoped>
.stores {
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: hidden
}

.stores .catalog {
    position: relative;
    width: calc(100% - 350px);
    padding: 49px 0 50px 0;
    overflow: scroll;
}

.stores .catalog::-webkit-scrollbar {
    width: 0px;
}

.stores .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 25px 0;
}

.stores .catalog .list {
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.stores .catalog .list .list-item {
    display: flex;
    align-items: center;
    height: 50px;
    background: #fff;
    border-bottom: solid 1px #d7d7d7;
}

.stores .catalog .list .list-item:first-of-type {
    border-radius: 5px 5px 0 0;
}

.stores .catalog .list .list-item:last-of-type {
    border: 0;
    border-radius: 0 0 5px 5px;
}

.stores .catalog .list .list-item .status {
    width: 100px;
    padding: 0 25px;
}

.stores .catalog .list .list-item .status .pill {
    display: flex;
    width: fit-content;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
    background: #eeeeee;
    border-radius: 5px;
}

.stores .catalog .list .list-item .status .pill.O {
    color: #fff;
    background: #00cb7d;
}

.stores .catalog .list .list-item .status .pill.C {
    color: #fff;
    background: #ffcc8d;
}

.stores .catalog .list .list-item .status .pill.A {
    color: #fff;
    background: #cd5c5c;
}

.stores .catalog .list .list-item .name {
    width: calc(50% - 50px);
    font-weight: 600;
    text-decoration: none;
}

.stores .catalog .list .list-item .city {
    width: calc(50% - 50px);
}





.stores .filters {
    position: relative;
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 1px;
    transition: .5s;
    overflow-y: auto;
}

.stores .filters::-webkit-scrollbar {
    width: 0px;
}

.stores .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.stores .filters .group {
    padding: 0 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.stores .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    border: 0;
    outline: 0;
}

.stores .filters .group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.stores .filters .group .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
}

.stores .filters .group .items .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.stores .filters .group .items .item .row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.stores .filters .group .items .item .row .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #eeeeee;
    border-radius: 50%;
    cursor: pointer;
}

.stores .filters .group .items .item .row .checked {
    width: 14px;
    height: 14px;
    background: #0071d7;
    border-radius: 50%;
}

.stores .filters .create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #0071d7;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}

.stores .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: #0171d7;
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}
</style>
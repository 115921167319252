import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '../views/Dashboard/Overview.vue'

import Login from '../views/Authenticate/Login.vue'

import ClientOverview from '../views/Clients/Overview.vue'
import ClientItem from '../views/Clients/Item.vue'

import ArticlesOverview from '../views/Articles/Overview.vue'
import ArticleItem from '../views/Articles/Item.vue'

import Filters from '../views/Filters/Overview.vue'

import Stores from '../views/Stores/Overview.vue'
import StoresItem from '../views/Stores/Item.vue'

import WebsiteOverview from '../views/Website/Overview.vue'
import WebsiteItem from '../views/Website/Item.vue'

import LeadsOverview from '../views/Leads/Overview.vue'

import NarrowcastingScreen from '../views/Narrowcasting/Screen.vue'

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        meta: {
            Auth: true,
            Title: 'Dashboard'
        },
        component: Dashboard
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            Title: 'Inloggen'
        },
        component: Login
    },
    {
        path: '/klanten/:id',
        name: 'ClientItem',
        meta: {
            Auth: true,
            Title: 'Klanten'
        },
        component: ClientItem
    },
    {
        path: '/klanten',
        name: 'ClientOverview',
        meta: {
            Auth: true,
            Title: 'Klanten'
        },
        component: ClientOverview
    },
    {
        path: '/artikelen/:id',
        name: 'ArticleItem',
        meta: {
            Auth: true,
            Title: 'Artikelen'
        },
        component: ArticleItem
    },
    {
        path: '/artikelen',
        name: 'ArticlesOverview',
        meta: {
            Auth: true,
            Title: 'Artikelen'
        },
        component: ArticlesOverview
    },
    {
        path: '/filters',
        name: 'Filters',
        meta: {
            Auth: true,
            Title: 'Filters'
        },
        component: Filters
    },
    {
        path: '/winkels/:id',
        name: 'StoresItem',
        meta: {
            Auth: true,
            Title: 'Winkels'
        },
        component: StoresItem
    },
    {
        path: '/winkels',
        name: 'Stores',
        meta: {
            Auth: true,
            Title: 'Winkels'
        },
        component: Stores
    },
    {
        path: '/website/:page/:id',
        name: 'WebsiteItem',
        meta: {
            Auth: true,
            Title: 'Website'
        },
        component: WebsiteItem
    },
    {
        path: '/website/:page',
        name: 'WebsiteOverview',
        meta: {
            Auth: true,
            Title: 'Website'
        },
        component: WebsiteOverview
    },
    {
        path: '/leads/:id?',
        name: 'LeadsOverview',
        meta: {
            Auth: true,
            Title: 'Leads'
        },
        component: LeadsOverview
    },
    {
        path: '/narrowcasting',
        name: 'NarrowcastingScreen',
        meta: {
            Auth: false,
            Title: 'Narrowcasting'
        },
        component: NarrowcastingScreen
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    // SET LOADER
    setTimeout(() => {
        document.querySelector('.bar-loader').classList.add('active')
    }, 250)

    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.add('third')
    }, 500)

    // CHECK AUTH
    if(to.meta.Auth && !localStorage.getItem('WAT')) {
        router.push({ path: '/login' })
    } else {
        next()
    }

    // SET TITLE
    if(to.meta.Title) {
        document.title = to.meta.Title + ' | Wheels'
    } else {
        document.title = 'Wheels'
    }
})

router.afterEach(() => {
    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.remove('third')
        document.querySelector('.bar-loader .fill').classList.add('full')
    }, 1000)

    setTimeout(() => {
        document.querySelector('.bar-loader').classList.remove('active')
    }, 1500)

    setTimeout(() => {
        document.querySelector('.bar-loader .fill').classList.remove('full')
    }, 2000)
})

export default router
<template>
    <div class="website" v-if="$route.params.page == 'pagina'">
        <div class="catalog">
            <span class="title">Pagina's</span>
            <div class="list">
                <div class="list-item" v-for="(Page, i) in FilteredPages" :key="i">
                    <div class="select">
                        <div class="checkbox" @click="Page.Active = !Page.Active">
                            <div class="checked" v-if="Page.Active"></div>
                        </div>
                    </div>
                    <span class="status">
                        <span class="pill active" v-if="Page.Status == 'O'">Actief</span>
                        <span class="pill concept" v-if="Page.Status == 'C'">Concept</span>
                        <span class="pill archive" v-if="Page.Status == 'A'">Archief</span>
                    </span>
                    <router-link :to="'/website/pagina/' + Page.Identifier" class="name">{{ Page.Title }}</router-link>
                    <span class="template">{{ Page.Template }}</span>
                    <span class="date">{{ SetDate(Page.Created) }}</span>
                    <div class="shortcuts">
                        <svg fill="#333333" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><g><path d="M220,48a12.01343,12.01343,0,0,0-12-12H48a11.95851,11.95851,0,0,0-8.4043,3.44824c-.02539.02442-.05566.041-.081.06641s-.042.05517-.06641.081A11.95851,11.95851,0,0,0,36,48V208a12.01343,12.01343,0,0,0,12,12H208a11.95851,11.95851,0,0,0,8.4043-3.44824c.02539-.02442.05566-.041.08105-.06641s.042-.05517.06641-.08105A11.95851,11.95851,0,0,0,220,208Zm-8,0V206.34375L49.65674,44H208A4.00427,4.00427,0,0,1,212,48ZM44,208V49.65625L206.34326,212H48A4.00427,4.00427,0,0,1,44,208Z"></path></g></svg>
                        <svg fill="#333333" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><g><path d="M220,48a12.01343,12.01343,0,0,0-12-12H48a11.95851,11.95851,0,0,0-8.4043,3.44824c-.02539.02442-.05566.041-.081.06641s-.042.05517-.06641.081A11.95851,11.95851,0,0,0,36,48V208a12.01343,12.01343,0,0,0,12,12H208a11.95851,11.95851,0,0,0,8.4043-3.44824c.02539-.02442.05566-.041.08105-.06641s.042-.05517.06641-.08105A11.95851,11.95851,0,0,0,220,208Zm-8,0V206.34375L49.65674,44H208A4.00427,4.00427,0,0,1,212,48ZM44,208V49.65625L206.34326,212H48A4.00427,4.00427,0,0,1,44,208Z"></path></g></svg>
                        <svg fill="#333333" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg"><g><path d="M220,48a12.01343,12.01343,0,0,0-12-12H48a11.95851,11.95851,0,0,0-8.4043,3.44824c-.02539.02442-.05566.041-.081.06641s-.042.05517-.06641.081A11.95851,11.95851,0,0,0,36,48V208a12.01343,12.01343,0,0,0,12,12H208a11.95851,11.95851,0,0,0,8.4043-3.44824c.02539-.02442.05566-.041.08105-.06641s.042-.05517.06641-.08105A11.95851,11.95851,0,0,0,220,208Zm-8,0V206.34375L49.65674,44H208A4.00427,4.00427,0,0,1,212,48ZM44,208V49.65625L206.34326,212H48A4.00427,4.00427,0,0,1,44,208Z"></path></g></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="filters" :class="{ loader: Loader }">
            <span class="title">Filter</span>

            <div class="group" :class="{ active: Search }">
                <input v-model="Search" @change="GetPages(true)" class="search" type="text" placeholder="Zoek een pagina">
            </div>

            <span class="create" @click="this.$root.OpenCreatePage">Pagina aanmaken</span>

            <span class="reset" v-if="Search.length > 0 || Filters.length > 0 || Selected.length > 0" @click="Reset">Reset</span>

            <div class="group" v-if="Pages">
                <span class="group-title">Soort</span>
                <div class="items">
                    <div class="item" v-for="(Page, i) in new Set(Pages.map(item => item.Template))" :key="i">
                        <div class="row">
                            <div class="checkbox" @click="SetFilter('Template', Page)">
                                <div class="checked" v-if="Filters.Template == Page"></div>
                            </div>
                            <span class="name">{{ Page }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="group" v-if="Pages">
                <span class="group-title">Status</span>
                <div class="items">
                    <div class="item" v-for="(Page, i) in new Set(Pages.map(item => item.Status))" :key="i">
                        <div class="row">
                            <div class="checkbox" @click="SetFilter('Status', Page)">
                                <div class="checked" v-if="Filters.Status == Page"></div>
                            </div>
                            <span class="name">{{ SetStatus(Page) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="website-menu" v-if="$route.params.page == 'menu'">
        <div class="menu">
            <span class="item" :class="{ active: Menu == 'Primary' }" @click="Menu = 'Primary', GetMenus()">Hoofdmenu</span>
            <span class="item" :class="{ active: Menu == 'Secondary' }" @click="Menu = 'Secondary', GetMenus()">Secundair</span>
            <span class="item" :class="{ active: Menu == 'Top' }" @click="Menu = 'Top', GetMenus()">Top</span>
            <span class="item" :class="{ active: Menu == 'Message' }" @click="Menu = 'Message', GetMenus()">Aankondiging</span>
            <span class="save" @click="UpdateMenus">Opslaan</span>
        </div>
        <div class="block" v-if="Menu == 'Primary'">
            <div class="items">
                <div class="item" v-for="(Item, i) in Primary.Items" :key="i">
                    <span v-if="Item.Title" class="title">{{ Item.Title }}</span>
                    <span v-else class="title">Titel</span>
                    <div class="item-shortcuts">
                        <svg class="left" :class="{ disabled: i == 0 }" @click="OrderMenuItem(i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        <svg class="right" :class="{ disabled: i == Primary.Items.length - 1 }" @click="OrderMenuItem(i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                    </div>
                    <div class="input">
                        <span>Titel</span>
                        <input type="text" v-model="Item.Title" placeholder="Bijv. Fietsen">
                    </div>
                    <div class="input">
                        <span>Pagina</span>
                        <input type="text" v-model="Item.Link" placeholder="Bijv. /stadfietsen">
                    </div>
                    <div class="input">
                        <span>Kleur</span>
                        <input type="text" v-model="Item.Color" placeholder="Bijv. #e0e0e0">
                    </div>
                    <div class="item-menu">
                        <span class="more" @click="SelectMenuItem(false)" v-if="Primary.ActiveSub == Item">Inklappen</span>
                        <span class="more" @click="SelectMenuItem(Item)" v-else>Uitklappen</span>
                        <span class="delete" @click="DeleteMenuItem(Item)">Verwijderen</span>
                    </div>
                </div>
                <div class="create" @click="CreateMenuItem">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#333333"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    <span>Nieuw menu item</span>
                </div>
            </div>
            <div class="columns" v-if="Primary.ActiveSub">
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.First" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.First, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.First.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.First, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.First, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.First.length <= 10" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.First)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#0171d7"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Second" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Second, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Second.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Second, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Second, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Second.length <= 10" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Second)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#0171d7"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Third" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Third, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Third.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Third, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Third, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Third.length <= 10" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Third)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#0171d7"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
                <div class="column">
                    <div class="item" v-for="(SubItem, i) in Primary.ActiveSub.SubItems.Fourth" :key="i">
                        <span v-if="SubItem.Title" class="title">{{ SubItem.Title }}</span>
                        <span v-else class="title">Titel</span>
                        <div class="item-shortcuts">
                            <svg class="left" :class="{ disabled: i == 0 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i, i - 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                            <svg class="right" :class="{ disabled: i == Primary.ActiveSub.SubItems.Fourth.length - 1 }" @click="OrderSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i, i + 1)" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#333333"></path></g></svg>
                        </div>
                        <div class="input">
                            <span>Soort</span>
                            <select v-model="SubItem.Type">
                                <option value="Link">Link</option>
                                <option value="Text">Text</option>
                                <option value="Empty">Leeg</option>
                            </select>
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Titel</span>
                            <input type="text" v-model="SubItem.Title">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Text' || SubItem.Type == 'Empty' }">
                            <span>Link</span>
                            <input type="text" v-model="SubItem.Link">
                        </div>
                        <div class="input" :class="{ disabled: SubItem.Type == 'Empty' }">
                            <span>Kleur</span>
                            <input type="text" v-model="SubItem.Color">
                        </div>
                        <span class="delete" @click="DeleteSubMenuItem(Primary.ActiveSub.SubItems.Fourth, i)">Verwijderen</span>
                    </div>
                    <div class="create" v-if="Primary.ActiveSub.SubItems.Fourth.length <= 10" @click="CreateSubMenuItem(Primary.ActiveSub.SubItems.Fourth)">
                        <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#0171d7"><g><g><g><g><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="12" x2="12" y1="19" y2="5"></line><line fill="none" stroke="#333333" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="5" x2="19" y1="12" y2="12"></line></g></g></g></g></svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="block" v-if="Menu == 'Top'">
            <div class="top-item">
                <span class="title">Tekstregel</span>
                <input type="text" v-model="Top.String">
                <span class="activate" v-if="Top.Method !== 'String'" @click="Top.Method = 'String'">Selecteren</span>
                <span class="activate active" v-else>Actief</span>
            </div>
            <div class="divider"></div>
            <div class="top-item">
                <span class="title">Unique Selling Point (door komma's gescheiden)</span>
                <input type="text" v-model="Top.USP">
                <span class="activate" v-if="Top.Method !== 'USP'" @click="Top.Method = 'USP'">Selecteren</span>
                <span class="activate active" v-else>Actief</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticlesOverview',

    data () {
        return {
            Loader: false,
            Pages: [],
            Search: '',
            Filters: {
                Template: '',
                Status: ''
            },
            Selected: [],
            Menu: 'Top',
            Primary: {
                Active: 'Primary',
                ActiveSub: false,
                Items: []
            },
            Top: {
                Method: 'USP',
                String: '',
                USP: []
            }
        }
    },

    mounted () {
        this.GetPages()
        this.GetMenus()
    },

    methods: {
        GetPages () {
            this.$http.get('https://api.wheels.nl/pages').then((response) => {
                if(response.data.pages) { this.Pages = response.data.pages }
            })
        },

        GetMenus () {
            this.$http.get(`https://api.wheels.nl/pages/menu/${this.Menu}`).then((response) => {
                if(response.data.primary) { this.Primary.Items = response.data.primary }
                if(response.data.top) {
                    this.Top.Method = response.data.top.Method
                    this.Top.String = response.data.top.String
                    this.Top.USP = response.data.top.USP
                }
            })
        },

        SetFilter (e, a) {
            if(this.Filters[e] == a) {
                this.Filters[e] = ''
            } else {
                this.Filters[e] = a
            }
        },

        SetStatus (e) {
            if(e == 'O') {
                return 'Actief'
            } else if(e == 'A') {
                return 'Archief'
            } else if(e == 'C') {
                return 'Concept'
            } else {
                return e
            }
        },

        SetDate (e) {
            if(this.$moment().isSame(e, 'day')) {
                return this.$moment(e).format('HH:mm')
            } else {
                return this.$moment(e).format('DD-MM')
            }
        },

        Reset () {
            this.Filtered = []
            this.Search = ''
        },

        CreateMenuItem () {
            this.Primary.Items.push({
                Title: '',
                Link: '',
                Color: '',
                SubItems: {
                    First: [],
                    Second: [],
                    Third: [],
                    Fourth: []
                }
            })
        },

        OrderMenuItem (e, a) {
            var tmp = this.Primary.Items[e];
            this.Primary.Items[e] = this.Primary.Items[a];
            this.Primary.Items[a] = tmp;
        },

        SelectMenuItem (e) {
            if(e) {
                this.Primary.ActiveSub = e
            } else {
                this.Primary.ActiveSub = false
            }
        },

        DeleteMenuItem (e) {
            this.Primary.Items = this.Primary.Items.filter(item => item !== e)
        },

        CreateSubMenuItem (e) {
            e.push({
                Type: 'Link',
                Title: '',
                Link: '',
                Color: ''
            })
        },

        OrderSubMenuItem (e, a, z) {
            var tmp = e[a];
            e[a] = e[z];
            e[z] = tmp;
        },

        DeleteSubMenuItem (e, a) {            
            e.splice(a, 1)
        },

        UpdateMenus () {
            if(this.Menu == 'Primary') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'Primary',
                    Items: this.Primary.Items
                }).then(() => {
                    this.$toast.open({
                        message: 'Menu opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            } else if(this.Menu == 'Top') {
                this.$http.patch('https://api.wheels.nl/pages/menu', {
                    Active: 'Top',
                    Method: this.Top.Method,
                    String: this.Top.String,
                    USP: this.Top.USP
                }).then(() => {
                    this.$toast.open({
                        message: 'Menu opgeslagen',
                        position: 'top-right',
                        type: 'success'
                    })
                })
            }
        }
    },

    computed: {
        FilteredPages () {
            var Pages = this.Pages

            if(this.Filters.Template) {
                Pages = this.Pages.filter(item => item.Template == this.Filters.Template)
            }

            if(this.Filters.Status) {
                Pages = this.Pages.filter(item => item.Status == this.Filters.Status)
            }

            if(this.Search) {
                Pages = this.Pages.filter(item => item.Name.toString().toLowerCase().includes(this.Search.toString().toLowerCase()))
            }

            return Pages
        }
    }
}
</script>

<style scoped>
.website {
    display: flex;
    gap: 0 50px;
    width: 100%;
    height: 100%;
    padding: 0 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: hidden;
}

.website .filters {
    position: relative;
    width: 350px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px 0;
    padding: 50px 1px;
    transition: .5s;
    overflow-y: auto;
}

.website .filters.loader {
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.website .filters::-webkit-scrollbar {
    width: 0px;
}

.website .filters .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
}

.website .filters .menu .list {
    width: 32px;
    height: 32px;
    fill: #000;
    cursor: pointer;
}

.website .filters .menu .blocks {
    width: 24px;
    height: 24px;
    fill: #000;
    cursor: pointer;
}





.website .filters .reset {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
    background: #0171d7;
    font-weight: 500;
    color: #fff;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
}





.website .filters .group {
    padding: 0 15px;
    background: #ffffff;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.website .filters .group.active {
    outline: solid 1px #0171d7;
    border: solid 1px #0171d7;
}

.website .filters .group .group-title {
    display: block;
    font-weight: 700;
    margin: 20px 0 10px 0;
}

.website .filters .group .selected {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.website .filters .group .selected .item {
    display: flex;
    align-items: center;
    height: 25px;
    padding: 0 10px;
    font-size: 14px;
    background: #0371d7;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.website .filters .group .divider {
    border-bottom: solid 1px #eeeeee;
    padding: 15px 0 0 0;
    margin: 0 0 15px 0;
}

.website .filters .group .search {
    display: block;
    width: 100%;
    height: 50px;
    padding: 15px;
    background: #ffffff;
    border: 0;
    outline: 0;
}

.website .filters .group .search.active {
    outline: solid 1px #0171d7;
    border: solid 1px #0171d7;
}

.website .filters .group .items {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 250px;
    padding: 0 0 25px 0;
    overflow: auto;
}

.website .filters .group .items::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.website .filters .group .items .item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.website .filters .group .items .item .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #eeeeee;
    border-radius: 5px;
    cursor: pointer;
}

.website .filters .group .items .item .checkbox .checked {
    width: 14px;
    height: 14px;
    background: #0071d7;
    border-radius: 2.5px;
}

.website .filters .group .items .item .count {
    margin: 0 0 0 auto;
    font-size: 12px;
    opacity: 0.25;
}

.website .filters .group .items .item .row {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.website .filters .group .items .item .row .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #eeeeee;
    border-radius: 50%;
    cursor: pointer;
}

.website .filters .group .items .item .row .checkbox .checked {
    width: 14px;
    height: 14px;
    background: #0071d7;
    border-radius: 50%;
}

.website .filters .group .items .item .subitem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 0 0 35px;
    cursor: pointer;
}

.website .filters .group .items .item .subitem .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #eeeeee;
    border-radius: 5px;
}

.website .filters .group .items .item .subitem .checkbox .checked {
    width: 14px;
    height: 14px;
    background: #0071d7;
    border-radius: 2.5px;
}

.website .filters .create {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #0071d7;
    color: #fff;
    font-weight: 600;
    outline: solid 1px transparent;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}





.website .catalog {
    position: relative;
    width: calc(100% - 350px);
    padding: 49px 0 50px 0;
    overflow: scroll;
}

.website .catalog::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.website .catalog .title {
    display: block;
    width: 100%;
    font-size: 30px;
    font-weight: 300;
    margin: 0 0 25px 0;
}

.website .catalog .list {
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.website .catalog .list .list-item {
    display: flex;
    align-items: center;
    height: 50px;
    background: #fff;
    border-bottom: solid 1px #d7d7d7;
}

.website .catalog .list .list-item:first-of-type {
    border-radius: 5px 5px 0 0;
}

.website .catalog .list .list-item:last-of-type {
    border: 0;
    border-radius: 0 0 5px 5px;
}

.website .catalog .list .list-item .select {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
}

.website .catalog .list .list-item .select .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #eeeeee;
    border-radius: 5px;
    cursor: pointer;
}

.website .catalog .list .list-item .select .checkbox .checked {
    width: 14px;
    height: 14px;
    background: #0071d7;
    border-radius: 2.5px;
}

.website .catalog .list .list-item .status {
    width: 100px;
}

.website .catalog .list .list-item .status .pill {
    display: flex;
    width: fit-content;
    height: 20px;
    padding: 0 5px;
    font-size: 12px;
    font-weight: 500;
    background: #eeeeee;
    border-radius: 5px;
}

.website .catalog .list .list-item .status .pill.active {
    color: #fff;
    background: #00cb7d;
}

.website .catalog .list .list-item .status .pill.concept {
    color: #fff;
    background: #ffcc8d;
}

.website .catalog .list .list-item .status .pill.archive {
    color: #fff;
    background: #cd5c5c;
}

.website .catalog .list .list-item .name {
    width: calc(100% - 550px);
    text-decoration: none;
}

.website .catalog .list .list-item .name:hover {
    text-decoration: underline;
    cursor: pointer;
}

.website .catalog .list .list-item .template {
    width: 150px;
}

.website .catalog .list .list-item .date {
    width: 150px;
}

.website .catalog .list .list-item .shortcuts {
    width: 100px;
    display: flex;
    gap: 5px;
}

.website .catalog .list .list-item .shortcuts svg {
    width: 20px;
    opacity: 0.5;
}





.website-menu {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
    height: 100%;
    padding: 50px;
    background: #eeeeee;
    border: solid 1px #d7d7d7;
    border-radius: 10px 0 0 0;
    overflow: auto;
}

.website-menu .menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.website-menu .menu .item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: 0 25px;
    border-bottom: solid 2px #d7d7d7;
    cursor: pointer;
}

.website-menu .menu .item.active {
    color: #0171d7;
    font-weight: 700;
    border-bottom: solid 2px #0171d7;
}

.website-menu .menu .save {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    min-width: 250px;
    padding: 0 15px;
    margin: 0 0 0 125px;
    background: #0171d7;
    color: #fff;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.website-menu .block {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website-menu .block .top-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.website-menu .block .top-item .title {
    font-size: 20px;
    font-weight: 500;
}

.website-menu .block .top-item input {
    width: 100%;
    padding: 5px;
    border: solid 1px #eeeeee;
    border-radius: 5px;
}

.website-menu .block .activate {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: 40px;
    padding: 0 15px;
    background: #eeeeee;
    color: #333;
    font-weight: 700;
    border-radius: 10px;
    cursor: pointer;
}

.website-menu .block .activate.active {
    background: #8fbc8f;
    color: #fff;
}

.website-menu .block .divider {
    margin: 15px 0;
    border-bottom: solid 1px #eeeeee;
}

.website-menu .block .items {
    display: flex;
    gap: 15px;
    padding: 0 0 5px 0;
    overflow-x: auto;
}

.website-menu .block .items::-webkit-scrollbar {
    height: 5px;
    background: transparent;
    border-radius: 5px;
}

.website-menu .block .items::-webkit-scrollbar-thumb {
    background: #d7d7d7;
    border-radius: 5px;
    cursor: pointer;
}

.website-menu .block .items::-webkit-scrollbar-thumb:hover {
    background: #333;
}

.website-menu .block .items .item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 350px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.website-menu .block .items .item .title {
    margin: 0 0 25px 0;
    font-size: 20px;
    font-weight: 500;
}

.website-menu .block .items .item .item-shortcuts {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    gap: 10px;
}

.website-menu .block .items .item .item-shortcuts .left {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
}

.website-menu .block .items .item .item-shortcuts .right {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(180deg);
    cursor: pointer;
}

.website-menu .block .items .item .item-shortcuts .left.disabled, .website-menu .block .items .item .item-shortcuts .right.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website-menu .block .items .item .input {
    display: flex;
    align-items: center;
}

.website-menu .block .items .item .input span {
    width: 75px;
}

.website-menu .block .items .item .input input {
    width: 250px;
    padding: 10px;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.website-menu .block .items .item .item-menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 25px 0 0 0;
}

.website-menu .block .items .item .item-menu .more {
    color: #0171d7;
    font-weight: 500;
    cursor: pointer;
}

.website-menu .block .items .item .item-menu .delete {
    color: #cd5c5c;
    cursor: pointer;
}

.website-menu .block .items .create {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    min-width: 300px;
    min-height: 350px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
    cursor: pointer;
}

.website-menu .block .items .create svg {
    width: 50px;
    height: 50px;
}

.website-menu .block .columns {
    display: flex;
    gap: 25px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.website-menu .block .columns .column {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 25%;
    padding: 25px;
    background: #fff;
    border-radius: 10px;
}

.website-menu .block .columns .column .item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 25px;
    background: #eeeeee;
    border-radius: 10px;
}

.website-menu .block .columns .column .item .title {
    font-size: 20px;
    font-weight: 500;
}

.website-menu .block .columns .column .item .item-shortcuts {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    gap: 10px;
}

.website-menu .block .columns .column .item .item-shortcuts .left {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(90deg);
    cursor: pointer;
}

.website-menu .block .columns .column .item .item-shortcuts .right {
    min-width: 30px;
    min-height: 30px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
    transform: rotate(270deg);
    cursor: pointer;
}

.website-menu .block .columns .column .item .item-shortcuts .left.disabled, .website-menu .block .columns .column .item .item-shortcuts .right.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website-menu .block .columns .column .item .input {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.website-menu .block .columns .column .item .input.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.website-menu .block .columns .column .item .input input, .website-menu .block .columns .column .item .input select {
    height: 50px;
    padding: 10px;
    border: solid 1px #d7d7d7;
    border-radius: 5px;
}

.website-menu .block .columns .column .item .delete {
    margin: 0 0 0 auto;
    color: #cd5c5c;
    cursor: pointer;
}

.website-menu .block .columns .column .create {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;
    border: dashed 1px #333333;
    border-radius: 10px;
    cursor: pointer;
}

.website-menu .block .columns .column .create svg {
    width: 50px;
    height: 50px;
    stroke: #0171d7;
}
</style>